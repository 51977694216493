function is_flat_rate_pricing(pricing) {
  return pricing?.type === 'flat_rate'
}
function is_item_pricing(pricing) {
  return pricing?.type === 'item'
}
function is_pro_person_pricing(pricing) {
  return pricing?.type === 'pro_person'
}

function is_ticket_pricing(pricing) {
  return is_flat_rate_pricing(pricing) || is_pro_person_pricing(pricing)
}

function total_pricings_amount(flat_rate_ticket, participants, items) {
  let total = 0

  if (flat_rate_ticket && flat_rate_ticket.price > 0) {
    total = flat_rate_ticket.price
  } else if (participants && participants.length) {
    total = participants.filter(p => is_pro_person_pricing(p.ticket) && p.ticket.price > 0).reduce((sum, p) => sum + p.ticket.price, 0)
  }

  if (items && items.length) {
    total += items.filter(i => i.price > 0 && i.quantity > 0).reduce((sum, item) => sum + item.price * item.quantity, 0)
  }

  return total
}

export default {
  is_flat_rate_pricing,
  is_item_pricing,
  is_pro_person_pricing,
  is_ticket_pricing,
  total_pricings_amount,
}
